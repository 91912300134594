import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FILE_MAX_SIZE, MAX_UPLOAD_FILE, SIZE_IN_MB } from '@/helpers/image';

export type UploadedFileType = {
  id: string;
  imageUrl: string;
  imagePath?: string;
};

const useUploadAdditionalPhotos = () => {
  const { t } = useTranslation();

  const [photos, setPhotos] = useState<Array<UploadedFileType>>([]);

  const getUploadingFiles = useCallback(
    (files: FileList | null): { files: File[]; errors: string[] } => {
      const result = {
        files: [] as File[],
        errors: [],
      };

      if (!files) return result;

      const uploadingFiles = [...files];

      const hasLargeFile = uploadingFiles.some((f) => f.size > FILE_MAX_SIZE);

      if (hasLargeFile) {
        result.errors.push(
          t('QC_V2.DEFECT.MAX_UPLOAD_SIZE', { size: `${SIZE_IN_MB} MB` })
        );
      }

      const uploadedLength = MAX_UPLOAD_FILE - photos.length;

      if (uploadedLength <= 0) return result;

      result.files = uploadingFiles
        .slice(0, uploadedLength)
        .filter((f) => f.size <= FILE_MAX_SIZE)
        .map(
          (f) =>
            new File([f], `${Date.now()}-${(f as File).name}`, {
              type: f.type,
            })
        );

      return result;
    },
    [photos]
  );

  const deleteFile = useCallback(
    (id: string) => {
      const remainPhotos = photos.filter((p) => p.id !== id);
      setPhotos(remainPhotos);
      return remainPhotos;
    },
    [photos]
  );

  const updatePhotos = useCallback(
    (uploadPhotos: UploadedFileType[]) =>
      setPhotos((prev) => [...prev, ...uploadPhotos]),
    []
  );

  return {
    photos,
    getUploadingFiles,
    deleteFile,
    updatePhotos,
  };
};

export default useUploadAdditionalPhotos;
