import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { toWeightUnit } from '@/helpers/unit';
import { useQcMode } from '@/hooks/use-qcMode';
import AdditionalItems from './AdditionalItems';
import AdditionalPhotos from './AdditionalPhotos';
import AgentInternalComment from './AgentInternalComment';
import AgentPublicComment from './AgentPublicComment';
import RefurbishmentInfo from './RefurbishmentInfo';
import DecimalField from '../Common/Field/DecimalField';
import { ErrorType } from '../QcPanel/QcPanel';

export default function AdditionalInfo({
  showHallMarksAndWeight,
  isWeightHallmarkRequired,
  errors,
}: {
  showHallMarksAndWeight: boolean;
  isWeightHallmarkRequired: boolean;
  errors?: ErrorType[];
}) {
  const { t } = useTranslation();

  const { weight, setWeight, hallmarks, setHallmarks } =
    useQualityControlTool();

  const { isViewMode } = useQcMode();

  const weightError = useMemo(() => {
    if (weight) return undefined;
    return errors && errors.find((e) => e.field === 'weight');
  }, [errors, weight]);

  const hallmarkError = useMemo(() => {
    if (hallmarks) return undefined;
    return errors && errors.find((e) => e.field === 'hallmarks');
  }, [errors, hallmarks]);

  return (
    <Stack gap={1}>
      <AdditionalItems isViewMode={isViewMode} />

      <RefurbishmentInfo isViewMode={isViewMode} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          padding: '1rem',
          pt: 0,
        }}
      >
        {showHallMarksAndWeight && (
          <Stack mt={1.25} gap={1.5}>
            <DecimalField
              data-testid="weight_input_text"
              id="weight"
              value={weight}
              onChange={setWeight}
              label={toWeightUnit(t('QC_V2.DEFECT.WEIGHT'))}
              placeholder={t('QC_V2.MAIN.WEIGHT_PLACEHOLDER')}
              sx={{ mb: 2 }}
              required={isWeightHallmarkRequired}
              error={weightError?.field === 'weight'}
              helperText={weightError?.message}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isViewMode}
            />

            <TextField
              data-testid="hallmarks_input_text"
              id="hallmark"
              label={t('QC_V2.MAIN.HALLMARKS')}
              placeholder={t('QC_V2.MAIN.HALLMARK_PLACEHOLDER')}
              value={hallmarks}
              sx={{ mb: 2 }}
              required={isWeightHallmarkRequired}
              error={hallmarkError?.field === 'hallmarks'}
              helperText={hallmarkError?.message}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isViewMode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setHallmarks(event.target.value);
              }}
            />
          </Stack>
        )}

        <Stack gap={1.5}>
          <AdditionalPhotos
            title={t('QC_V2.MAIN.QC_INTERNAL_PHOTOS')}
            type="internal"
          />
          <AgentInternalComment />
        </Stack>

        <Stack mt={1.5} gap={1.5}>
          <AdditionalPhotos
            title={t('QC_V2.MAIN.QC_PUBLIC_PHOTOS')}
            type="public"
          />
          <AgentPublicComment />
        </Stack>
      </Box>
    </Stack>
  );
}
