import { Zoom, Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import usePhotoGallery from '@/hooks/use-photo-gallery';
import GalleryModal from './GalleryModal';

interface PhotoViewProps {
  photos: string[]; // photo full paths
  onClose: () => void;
  selectedIndex?: number;
}

const PhotoView = ({ photos, selectedIndex, onClose }: PhotoViewProps) => {
  const { ref, zoomIn, zoomOut, getPhotoBulletClass } =
    usePhotoGallery(selectedIndex);

  return (
    <GalleryModal onClose={onClose} zoomIn={zoomIn} zoomOut={zoomOut}>
      <Swiper
        ref={ref}
        initialSlide={selectedIndex || 0}
        slidesPerView={1}
        direction="vertical"
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<img class="${className} ${getPhotoBulletClass(index)}" src="${photos[index]}" />`;
          },
        }}
        zoom={true}
        keyboard={{
          enabled: true,
          pageUpDown: true,
        }}
        modules={[Zoom, Pagination, Keyboard]}
      >
        {photos.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="swiper-zoom-container">
                <img src={img} alt={img} loading="lazy" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </GalleryModal>
  );
};

export default PhotoView;
