import { TFunction } from 'i18next';
import { Theme } from '@/components/Theme';
import { PRODUCT_MATERIAL } from '@/types/ProductInfo';

export enum QC_PRODUCT_STATE {
  ACCEPTED = 'accepted',
  BEING_CONTROLLED = 'being_controlled',
  BEING_NEGOTIATED = 'being_negotiated',
  QC_EXIT = 'qc_exit',
  NEGOTIATION_ACCEPTED = 'negotiation_accepted',
  NEGOTIATION_REJECTED = 'negotiation_rejected',
  PENDING_CONTROL = 'pending_control',
  REJECTED = 'rejected',
  HELD = 'held',
}

export const QC_PRODUCT_DONE_STATE = [
  QC_PRODUCT_STATE.BEING_NEGOTIATED,
  QC_PRODUCT_STATE.ACCEPTED,
  QC_PRODUCT_STATE.REJECTED,
];

export enum QC_DECISION_STATE {
  CONTROL = 'control',
  ACCEPT = 'accept',
  NEGOTIATE = 'negotiate',
  REJECT = 'reject',
  HOLD = 'hold',
  TAKE_OVER_CONTROL = 'take_over_control',
}

export const QC_PRODUCT_STATUS = {
  OK: 'OK',
  KO: 'KO',
  NEGO: 'NEGO',
};

export const QC_PRODUCT_STATUS_MAPPING: Record<QC_PRODUCT_STATE, string> = {
  [QC_PRODUCT_STATE.ACCEPTED]: QC_PRODUCT_STATUS.OK,
  [QC_PRODUCT_STATE.REJECTED]: QC_PRODUCT_STATUS.KO,
  [QC_PRODUCT_STATE.BEING_NEGOTIATED]: QC_PRODUCT_STATUS.NEGO,
  [QC_PRODUCT_STATE.NEGOTIATION_ACCEPTED]: QC_PRODUCT_STATUS.NEGO,
  [QC_PRODUCT_STATE.NEGOTIATION_REJECTED]: QC_PRODUCT_STATUS.NEGO,
  [QC_PRODUCT_STATE.BEING_CONTROLLED]: '',
  [QC_PRODUCT_STATE.QC_EXIT]: '',
  [QC_PRODUCT_STATE.PENDING_CONTROL]: '',
  [QC_PRODUCT_STATE.HELD]: '',
};

export const LOCALE = 'en-GB';

export const getStatusMapping = (
  t: TFunction<'translation', undefined>
): Record<
  string,
  {
    label: string;
    color: string;
    background?: string;
  }
> => {
  return {
    [QC_PRODUCT_STATE.REJECTED]: {
      label: 'KO',
      color: Theme.palette.error.main,
    },
    [QC_PRODUCT_STATE.ACCEPTED]: {
      label: t('QC_V2.MAIN.OK')?.toUpperCase(),
      color: Theme.palette.success.main,
    },
    [QC_PRODUCT_STATE.HELD]: {
      label: t('QC_V2.MAIN.HELD')?.toUpperCase(),
      color: Theme.palette.info.main,
    },
    [QC_PRODUCT_STATE.BEING_NEGOTIATED]: {
      label: t('QC_V2.MAIN.QC_NEGO')?.toUpperCase(),
      color: Theme.palette.warning.main,
    },
    [QC_PRODUCT_STATE.NEGOTIATION_ACCEPTED]: {
      label: t('QC_V2.MAIN.NEGOTIATION_ACCEPTED')?.toUpperCase(),
      color: Theme.palette.warning.main,
    },
    [QC_PRODUCT_STATE.NEGOTIATION_REJECTED]: {
      label: t('QC_V2.MAIN.NEGOTIATION_REJECTED')?.toUpperCase(),
      color: Theme.palette.warning.main,
    },
    [QC_PRODUCT_STATE.BEING_CONTROLLED]: {
      label: t('QC_V2.MAIN.ON_GOING')?.toUpperCase(),
      color: Theme.palette.common.white,
      background: Theme.palette.grey[600],
    },
  };
};

export const PRODUCT_TOOK_OVER_ERROR = 'qc.another_agent_controlling';

export const QC_REQUIRED_JEWELRY_MATERIALS = [
  PRODUCT_MATERIAL.GOLD.toLowerCase(),
  PRODUCT_MATERIAL.WHITE_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.PINK_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.YELLOW_GOLD.toLowerCase(),
  PRODUCT_MATERIAL.SILVER.toLowerCase(),
  PRODUCT_MATERIAL.PLATINUM.toLowerCase(),
  PRODUCT_MATERIAL.GOLD_STEEL.toLowerCase(),
];
